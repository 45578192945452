<div class="page-game">
  <div class="preload" [ngStyle]="{'z-index': sessionEmpty ? '1':'-1'}">
    <img [src]="websiteJSONData.favicon"/>
    @if (sessionEmpty) {
      <span class="text-must-login">
        Harap Login Terlebih Dahulu Untuk Melanjutkan.
      </span>
      <a [routerLink]="['/login']">
        <button class="btn-login">
          Login
        </button>
      </a>
    }
  </div>
  @if(provider.publisher_code !== 'pg') {
    <!-- <div id="iframeGame"></div> -->
    <iframe id="iframeGame" width="100%" height="100%" frameBorder="0" [src]="gameURL"></iframe>
  } @else {
    <!-- <div id="iframeGameDoc"></div> -->
  <iframe id="iframeGameDoc"
    width="100%"
    height="100%"
    frameBorder="0"
    [srcdoc]="gameURL"
  ></iframe>
  } @if(showFirstOverlay) {
    @if(currentScreenSize === 'mobile'){
      <!-- <div class="first-overlay mobile" (click)="showFirstOverlay = false">
        <p>Layer akan tertutup otomatis dalam waktu {{timeCountSecond}} detik, atau klik disini</p>
      </div> -->
    }@else{
      <div class="first-overlay" (click)="showFirstOverlay = false">
        <div class="h4">Tekan SHIFT + TAB untuk membuka MENU</div>
        <p>Layer akan tertutup otomatis dalam waktu {{timeCountSecond}} detik, atau klik disini</p>
      </div>
    }
  } @if(showMenu) {
  <div class="menu-overlay">
    <div class="container-xxl my-0 mx-auto h-100">
      <div class="d-flex flex-column justify-content-between h-100 w-100">
        <div class="w-100">
          <div class="d-flex justify-content-between w-100 top-menu">
            <a (click)="goBackHistory()">
              <button class="back-to-lobby">Back To Lobby</button>
            </a>
            <img class="img-provider" [src]="
                        '../../../template/green/assets/images/providers/' +
                        (provider.name | slugName) +
                        '.webp'
                      " [alt]="provider.name | slugName" />
            <button class="close-menu" (click)="showMenu = false">
              <fa-icon [icon]="faXmark" [size]="'xl'"></fa-icon>
            </button>
          </div>
          <div class="d-flex justify-content-between w-100 middle-menu">
            <div class="middle-left-menu">
              <div class="user-info">
                <img
                  src="../../../template/green/assets/images/status-gold.png"
                  width="85px"
                  height="80px"
                />
                <div class="detail-info">
                  <img
                    src="../../../template/green/assets/images/rank/{{(userGroup.name).toLowerCase()}}.webp"
                    width="66px"
                    height="20px"
                  />
                  <div>
                    <p class="username">{{ username }}</p>
                    @if(balance == undefined){
                    <p class="balance">Loading...</p>
                    } @else {
                    <p class="balance">IDR {{balance | currency:' ' : 'symbol' : '1.0-0'}}</p>
                    }
                  </div>
                </div>
              </div>
              <a [routerLink]="['/withdrawal']">
                <button class="withdraw">
                  <fa-icon class="mb-2" [icon]="faMoneyBillTransfer" [size]="'xl'"></fa-icon>
                  Withdraw
                </button>
              </a>
              <a [routerLink]="['/deposit']">
                <button class="deposit">
                  <fa-icon class="mb-2" [icon]="faWallet" [size]="'xl'"></fa-icon>
                  Deposit
                </button>
              </a>
            </div>
            <div class="middle-right-menu">
              @if(promoJSONData.length > 0) {
                @for (item of promoJSONData; track item;let idx = $index) {
                  @if(idx <2){
                    @if(item['banner_url'] !== '' && item['banner_url'] !== '#') {
                      <a [routerLink]="['/promotion']" [queryParams]="{ part: item._id }" class="w-50">
                        <img [src]="item.thumbnail_url" [alt]="item.title" class="w-100" />
                      </a>
                    }
                  }
              }
              }
            </div>
          </div>
        </div>
        <div
          class="d-flex flex-column justify-content-between w-100 bottom-menu"
        >
          <p>Cek Game Keren Lainnya</p>
          <app-list-games [data]="popularGamesData" />
        </div>
      </div>
    </div>
  </div>
  }
</div>
