import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { HttpClient, HttpHandlerFn, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, Optional, PLATFORM_ID, StateKey, TransferState, makeStateKey } from '@angular/core';
import { REQUEST } from '@componentsrc/express.tokens';
import { Request } from 'express';
import { DataService } from './data.service';
import { Observable, of, startWith, tap } from 'rxjs';

export const handleServerState = <T>(
  transferState: TransferState,
  stateKey: StateKey<T>,
  isServer: boolean,
): ((source$: Observable<T>) => Observable<T>) => {
  return (source$: Observable<T>) => {
    if (isServer) {
      return source$.pipe(tap(data => transferState.set(stateKey, data)))
    }

    if (transferState.hasKey(stateKey)) {
      return source$.pipe(
        startWith(transferState.get<T>(stateKey, {} as T)),
        tap(() => transferState.remove(stateKey)),
      )
    }

    return source$
  }
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private websiteConfig: any;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Optional() @Inject(REQUEST) private request: Request,
    private httpClient: HttpClient,
    private transferState: TransferState,
    private data: DataService
  ) { }

  public requestPOST(path: string, data: Record<string, any>): Observable<any> {
    const dataObservableKey = makeStateKey<Record<string, any>>(path);
    this.websiteConfig = this.data.websiteConfigData;

    let pathAPI = this.websiteConfig['path'] + '/' + this.websiteConfig['key_path'] + '/' + path;
    let serverHost: any = '';
    let serverProtocol: any = '';
    let serverURL: any = '';

    if (isPlatformServer(this.platformId)) {
      serverHost = this.request.get('host');
      serverProtocol = this.request.protocol;
      serverURL = serverProtocol + '://' + serverHost + pathAPI;
    }
    else if (isPlatformBrowser(this.platformId)) {
      serverHost = window.location.host;
      serverProtocol = window.location.protocol;
      serverURL = serverProtocol + '//' + serverHost + pathAPI;
    }

    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(serverURL, data, { headers }).pipe(
      handleServerState(this.transferState, dataObservableKey, isPlatformServer(this.platformId))
    );
  }
  public requestTEXT(path: string, data: Record<string, any>): Observable<any> {
    const dataObservableKey = makeStateKey<Record<string, any>>(path);
    this.websiteConfig = this.data.websiteConfigData;

    let pathAPI = this.websiteConfig['path'] + '/' + this.websiteConfig['key_path'] + '/' + path;
    let serverHost: any = '';
    let serverProtocol: any = '';
    let serverURL: any = '';

    if (isPlatformServer(this.platformId)) {
      serverHost = this.request.get('host');
      serverProtocol = this.request.protocol;
      serverURL = serverProtocol + '://' + serverHost + pathAPI;
    }
    else if (isPlatformBrowser(this.platformId)) {
      serverHost = window.location.host;
      serverProtocol = window.location.protocol;
      serverURL = serverProtocol + '//' + serverHost + pathAPI;
    }

    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.httpClient.post(serverURL, data, { headers, responseType:'text' })
  }

  public requestData(path: string, data: Record<string, any>): any {
    const dataKey = makeStateKey<Record<string, any>>(path);
    let resultData: any;
    this.websiteConfig = this.data.websiteConfigData;

    if (isPlatformServer(this.platformId)) {
      let pathAPI = this.websiteConfig['path'] + '/' + this.websiteConfig['key_path'] + '/' + path;
      let serverHost = this.request.get('host');
      let serverProtocol = this.request.protocol;
      let serverURL = serverProtocol + '://' + serverHost + pathAPI;

      let ip = this.request.headers['x-forwarded-for'] || this.request.socket.remoteAddress;
      console.log('client IP: ', ip);

      const headers = new HttpHeaders().set('Content-Type', 'application/json');

      this.httpClient.post(serverURL, data, { headers }).subscribe({
        next: (v) => {
          resultData = v;
          this.transferState.set(dataKey, v);
        },
        error: (e) => {
          console.log(e.message);
          if (Object.keys(e.error).length > 0) {
            this.transferState.set(dataKey, e.error);
          } else {
            let customError = {
              "request_id": "[NODE SERVER] " + path,
              "data": [],
              "messages": [{ "message": "[" + e.name + "] " + e.statusText, "status": "error" }],
              "code": e.status
            }
            this.transferState.set(dataKey, customError);
          }
        }, complete: () => { }
      });
    }
    // else if (isPlatformBrowser(this.platformId)) {
    // }
    if (this.transferState.hasKey(dataKey)) {
      resultData = this.transferState.get<Record<string, any>>(dataKey, {});
      console.log("client data path ['" + path + "']: ", resultData);
      this.transferState.remove(dataKey)
    }

    return resultData;

  }
}