import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, effect, Inject, model, PLATFORM_ID, signal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@componentpath/services/auth.service';
import { DataService } from '@componentpath/services/data.service';
import { RequestService } from '@componentpath/services/request.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faArrowCircleLeft, faCheck, faCircleCheck, faCircleChevronRight, faCircleInfo, faCircleQuestion, faImages, faShield, faTrashCan, faXmark } from '@fortawesome/free-solid-svg-icons';
import { MatBottomSheet, MatBottomSheetModule, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { FilterMobileComponent } from '../filter-mobile/filter-mobile.component';
import { Subscription } from 'rxjs';
import { A11y, Grid, Mousewheel } from 'swiper/modules';
import { SwiperOptions } from 'swiper/types';
import { DepositManualMobileComponent } from '@template/green/mobile/deposit-manual-mobile/deposit-manual-mobile.component';
import { RouterExtService } from '@componentpath/services/scripts.service';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SafeUrl } from '@angular/platform-browser';
import { SnackbarComponent } from '@template/green/snackbar/snackbar.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { QRCodeModule } from 'angularx-qrcode';

@Component({
  selector: 'app-deposit-mobile',
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, QRCodeModule, FontAwesomeModule, MatBottomSheetModule, DepositManualMobileComponent ],
  templateUrl: './deposit-mobile.component.html',
  styleUrl: './deposit-mobile.component.scss'
})
export class DepositMobileComponent {
  subscription: Subscription = new Subscription();

  faCircleQuestion = faCircleQuestion;
  faCircleChevronRight = faCircleChevronRight;
  faTrashCan = faTrashCan;
  faCircleInfo = faCircleInfo;
  faArrowCircleLeft = faArrowCircleLeft;
  faCircleCheck = faCircleCheck;
  faCheck = faCheck;
  faShield = faShield;
  faXmark = faXmark;
  faImages = faImages;

  currentMethodPayment: string = 'qris';

  statusPage = "form" //success, confirm, form, payment, promo, error;
  statusPageManual = model<any>('');
  manualDataConfirm = model<any>({});
  userProfile: any;
  // nextStepOption: boolean = false;
  // showFormPaymentAuto: boolean = false;
  // showFormPaymentManual: boolean = false;
  // showConfirmPayment: boolean = false;
  // hideSwitchMenu: boolean = false;
  currentActiveBank: any = {};
  currentActiveAccount: string = '';
  inputAmount: any = null;
  currentNominal: number = 0;
  userGroupData: Record<string, any> = {};
  bankCompanyList: Record<string, any> = {};
  depositConfig: Record<string, any> = {};
  loader: boolean = true;
  currentTitle: string = 'Deposit';

  currentSelectPaymentType: string = 'all';
  balance: any = undefined;
  promoList: Record<string, any>[] = [];
  noteDeposit = new FormControl('', []);
  searchPromo = new FormControl('', []);
  promoSearch: Record<string, any>[] = [];
  currentPromo: Record<string, any> = {};
  selectedPromo: Record<string, any> = {};
  // showPromo: boolean = false;
  showNotifText: string = '';
  showNotif: boolean = false;
  showError: boolean = false;
  disabledButton: boolean = true;
  disabledButtonCreate: boolean = false;
  disabledButtonSub: boolean = false;
  inProgress: boolean = false;
  autoData: any;
  manualData: any;
  qrCodeDownloadLink: SafeUrl = '';
  websiteJSONData: any = this.data.websiteJSONData;
  timeCountSecond: number = 5;
  isCorrectValue: number = 0;
  intervalStatus: any;


  //data manual dp
  bankListA: Record<string, any>[] = [];
  bankListB: Record<string, any>[] = [];
  bankListC: Record<string, any>[] = [];
  bankListALL: Record<string, any>[] = [];
  bankList: Record<string, any>[] = [];
  bankListCreateA: Record<string, any>[] = [];
  bankListCreateC: Record<string, any>[] = [];
  bankListCreateALL: Record<string, any>[] = [];
  isCreatedBank: boolean = false;
  currentCreatedBank: Record<string, any> = {};
  listSelectCreateBank: Record<string, any>[] = [];
  listSelectCreateEwallet: Record<string, any>[] = [];
  currentSelectCreateBank: string = 'bank';
  currentSelectedCreateBank: Record<string, any> = {};
  currentDataBankAdmin: Record<string, any> = {};
  currentDataBankUser: Record<string, any> = {};
  currentSelectDataBankUser: Record<string, any> = {};
  currentSelectDataBankAdmin: Record<string, any> = {};
  currentFee: number = 0;
  optionsBank: Array<any> = [];
  searchedOptions: Array<any> = [];
  searchedOptionsCompany: Array<any> = [];
  searchedCreateOptions: Array<any> = [];
  listSelectBankUser: Record<string, any>[] = [];
  listSelectBankUserBank: Record<string, any>[] = [];
  listSelectBankUserEwall: Record<string, any>[] = [];
  listSelectBankUserQris: Record<string, any>[] = [];
  listSelectBankUserCell: Record<string, any>[] = [];
  transactionConfig: Record<string, any> = {};
  searchBankInput: string = '';
  inputSearchCreate: string = '';
  inputUserBankNum: string = '';
  inputUserBankName: string = '';
  amountDepositNumber: number = 0;
  minDPAmount: number = 0;
  maxDPAmount: number = 0;
  typings: any;

  base64ImageData: any = '';
  imageFileName: string = '';


  public minutes: number = 0;
  public seconds: number = 0;
  public minutesShow: string = '';
  public secondsShow: string = '';
  private intervalId: any;
  arrayNominal: Array<number> = [];

  public config: SwiperOptions = {
    init: true,
    modules: [A11y, Mousewheel, Grid],
    slidesPerView: 1,
    grabCursor: false,
    loop: false,
    mousewheel: true,
    freeMode: true,

  };

  footerJSONData: any = this.data.footerJSONData;
  footerData: any = [];

  currentScreenSize: string = '';
  paramsVal = '';
  prevURL: string = '';

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public data: DataService,
    private auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private request: RequestService,
    private bottomSheet: MatBottomSheet,
    private snackBar: MatSnackBar,
    private routerExtService: RouterExtService
  ){
    // this.openBottomSheet();
    effect(()=>{
      // this.statusPageManual.set('confirm-bank');
      console.log(this.statusPageManual());
      console.log(this.manualDataConfirm());
      this.statusPage = this.statusPageManual();
      this.manualData = this.manualDataConfirm();
    });
  }

  copyToClipboard(id: string): void {
    if (isPlatformBrowser(this.platformId)) {
      navigator.clipboard.writeText(id).then(() => {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: { message: 'Nomor Rekening berhasil disalin', type: 'mobile-default' },
          duration: 3000,
          horizontalPosition: "center",
          verticalPosition: "top",
          panelClass: "notification-default"
        });
      }).catch(err => {
        console.error('Failed to copy ID to clipboard', err);
      });
    }
  }

  setArrayNominal(){
    let defaultArr = [10000, 20000, 50000, 100000, 500000, 1000000, 5000000, 10000000];
    for (let i = 0; i < defaultArr.length; i++) {
      const element = defaultArr[i];
      if(element >= this.depositConfig['qris_min_amount'] && element <= this.depositConfig['qris_max_amount']){
        this.arrayNominal.push(element);
      }else{
        if(element < this.depositConfig['qris_min_amount']){
          if (this.arrayNominal.includes(this.depositConfig['qris_min_amount']) === false) this.arrayNominal.push(this.depositConfig['qris_min_amount']);
        }
      }
      if(defaultArr.length === i+1){
          if (this.arrayNominal.includes(this.depositConfig['qris_max_amount']) === false) this.arrayNominal.push(this.depositConfig['qris_max_amount']);
      }
    }
  }

  getBase64(file: any, resize: boolean, sizeKB: number) {
    var scale = 0.7;

    if (sizeKB > 10000) {
      scale = 0.3;
    }
    if (sizeKB > 5000 && sizeKB < 10000) {
      scale = 0.4;
    }
    if (sizeKB > 3000 && sizeKB < 5000) {
      scale = 0.5;
    }

    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (resize) {
        this.base64Resize(reader.result, scale);
      } else {
        this.base64ImageData = reader.result;
      }
      this.uploadImage();
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  uploadImage() {
    if (this.manualData) {
      this.request.updateImageDeposit(this.auth.sessionValue, this.manualData['_id'], this.base64ImageData).subscribe({
        next: (v) => {
          const value = v['data'] as Record<string, any>;
          if (v['code'] === 200) {
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: { message: 'Image Berhasil di Upload', type: 'mobile-default' },
              duration: 3000,
              horizontalPosition: "center",
              verticalPosition: "top",
              panelClass: "notification-success"
            });
          }
        },
        error: (e) => {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: { message: e.error.messages[0].message, type: 'error' },
            duration: 3000,
            verticalPosition: 'top',
            panelClass: "notification-error"
          });
        },
        complete: () => { }
      })
    } else {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: { message: 'Image Gagal di Upload', type: 'error' },
        duration: 3000,
        verticalPosition: 'top',
        panelClass: "notification-error"
      });
    }
  }

  base64Resize(sourceBase64: any, scale: any) {

    const _scale = scale;
    var img = document.createElement('img');
    img.setAttribute("src", sourceBase64);

    img.onload = () => {
      var canvas = document.createElement('canvas');
      canvas.width = img.width * _scale;
      canvas.height = img.height * _scale;

      var ctx = canvas.getContext("2d");
      var cw = canvas.width;
      var ch = canvas.height;
      var maxW = img.width * _scale;
      var maxH = img.height * _scale;

      var iw = img.width;
      var ih = img.height;
      var scl = Math.min((maxW / iw), (maxH / ih));
      var iwScaled = iw * scl;
      var ihScaled = ih * scl;
      canvas.width = iwScaled;
      canvas.height = ihScaled;
      ctx!.drawImage(img, 0, 0, iwScaled, ihScaled);
      const newBase64 = canvas.toDataURL("image/jpeg", scl);
      // console.log(newBase64);
      this.base64ImageData = newBase64;
      return newBase64;
    }
  }

  onSelectFile(e: any) {
    // console.log(e)
    if (e.target.files.length > 0) {
      console.log(e)
      this.imageFileName = e.target.files[0].name;
      let sizeKB = e.target.files[0].size / 1024;
      let fileType = e.target.files[0].type;
      // console.log('File Type :', fileType);
      // console.log('Size :', e.target.files[0].size / 1024);
      // console.log(e.target.files[0].name)
      if (sizeKB > 1500) {
        this.getBase64(e.target.files[0], true, sizeKB);
      } else {
        if (fileType !== "image/png" && fileType !== "image/jpeg") {
          // this.uploadImage.setErrors({ 'invalidFileType': true });
          // this.uploadImage.markAllAsTouched();
          // console.log(this.uploadImage.touched)
        } else {
          this.getBase64(e.target.files[0], false, sizeKB);
        }
      }
    } else {
      this.imageFileName = 'File Not Selected';
      this.base64ImageData = '';
    }

  }

  openBottomSheet(): void {
    this.bottomSheet.open(FilterMobileComponent);
  }

  selectPromo() {
    this.showNotif = false;
    // this.showPromo = false;
    this.statusPage = 'form';
    if (this.selectedPromo['_id']) {
      this.currentPromo = this.selectedPromo;
    }
  }

  openPromoSearch() {
    this.showNotif = false;
    // this.showPromo = true;
    this.statusPage = 'promo'
  }

  resetPromo() {
    this.currentPromo = {};
  }

  getPromoList() {
    this.request.getPromoListBySource(this.auth.sessionValue, 'mobile', '').subscribe({
      next: (v) => {
        // console.log(v);
        const value = v as Record<string, any>;
        if (value['data']) {
          this.promoList = value['data'];
          this.promoSearch = this.promoList;
        }
      },
      error: (e) => {
        console.log(e);
      },
      complete: () => { }
    })
  }

  inputSearchPromo() {
    let codePromo = this.searchPromo.value;
    console.log(codePromo);
    if (codePromo !== '' && codePromo !== undefined && codePromo !== null) {
      this.request.getPromoCode(codePromo).subscribe({
        next: (v) => {
          // console.log(v);
          const value = v as Record<string, any>;
          this.promoSearch = [];
          this.promoSearch.push(value['data']);
        },
        error: (e) => {
          console.log(e);
          this.showNotif = true;
          this.showNotifText = e.error.messages[0].message;
        },
        complete: () => { }
      })
    } else {
      this.promoSearch = this.promoList;
    }
  }

  backButton() {
    if (isPlatformBrowser(this.platformId)) {
      if (isPlatformBrowser(this.platformId)) {
        this.prevURL = this.routerExtService.getPreviousUrl();
        if (this.prevURL === this.router.url) {
          if (this.prevURL === '/deposit?p=1' || this.prevURL === '/deposit?p=2' || this.prevURL === '/deposit?p=3'){
            this.router.navigate(['/deposit']);
          }else{
            this.router.navigate(['/']);
          }
        } else {
          if (this.paramsVal === '1') {
            if (this.prevURL === '/deposit?p=1') {
              this.router.navigate(['/']);
            } else {
              this.router.navigate(['/deposit']);
            }
          } else if (this.paramsVal === '2') {
            this.router.navigate([this.router.url.split('?')[0]], { queryParams: { "p": 1 } });
          } else if (this.paramsVal === '3') {
            if (this.prevURL === '/deposit?p=3') {
              this.router.navigate(['/']);
            } else {
              this.router.navigate(['/deposit']);
            }
          }else {
            this.router.navigateByUrl(this.prevURL);
          }
        }
      }
    }
  }

  selectMethodPayment(val: string) {
    if (this.statusPage === 'form') {
      this.currentMethodPayment = val;
      if (this.currentMethodPayment !== 'bank') {
        this.setArrayNominal();
        if (this.inputAmount) {
          this.updateValueNominal(this.inputAmount.toString());
        }
      }
    }
    // this.checkActiveDepositAuto();
  }

  setNominalChip(val: number) {
    this.currentNominal = val;
    this.inputAmount = this.currentNominal;
    this.disabledButton = false;
    // this.isCorrectValue = val % 1000;
  }

  updateValueNominal(e: any) {
    let rawNominal = e.split('.').join("");
    this.inputAmount = parseInt(rawNominal);
    // this.isCorrectValue = this.inputAmount % 1000;
    this.isCorrectValue = 0;
    this.setNominalChip(this.inputAmount);
    if (Number.isNaN(this.inputAmount)) {
      // console.log(this.inputAmount)
      this.disabledButton = true;
      this.isCorrectValue = 0;
    } else {
      // console.log(e);
      if ((this.inputAmount * 1000) >= this.depositConfig['qris_min_amount'] && (this.inputAmount * 1000) <= this.depositConfig['qris_max_amount']) {
        this.disabledButton = false;
      } else {
        this.disabledButton = true;
        this.isCorrectValue = 1;
      }
    }
  }

  confirmDeposit(val: string) {
    if (val === 'bank') {
      this.setDepositManual();
    } else {
      this.setDepositAuto();
    }
  }

  setDepositManual() {
      if (this.isCorrectValue === 0 && this.inputAmount >= this.minDPAmount && this.inputAmount <= this.maxDPAmount) {
  
        this.disabledButton = true;
        this.inProgress = true;
          let shortDateTime = dayjs().format('DD-MM-YYYY HH:mm:ss');

          let noteValue = this.noteDeposit.value;
    
          if (noteValue === null) {
            noteValue = '';
          }
    
          let promoID = '';
          let promoType = '';
          if (this.currentPromo['_id']) {
            promoID = this.currentPromo['_id'];
            promoType = this.currentPromo['type'];
          }
    
          // console.log('CALLED')
    
          this.request.createDeposit(this.auth.sessionValue, this.currentDataBankUser['_id'], this.currentActiveBank['_id'], this.inputAmount as string, shortDateTime, '', noteValue, promoType, promoID).subscribe({
            next: (v) => {
              const value = v as Record<string, unknown>;
              if (value['code'] === 200) {
                const data = value['data'] as Record<string, any>;
                this.manualData = value['data'];
                this.manualData['amount'] = parseInt(this.manualData['amount']);
                this.manualData['transaction_fee'] = parseInt(this.manualData['transaction_fee']);
                this.statusPage = 'confirm-bank';
                this.inProgress = false;
                // this.router.navigate(['/transaction']);
              }
            },
            error: (e) => {
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: { message: e.error.messages[0].message, type: 'error' },
                duration: 3000,
                verticalPosition: 'top',
                panelClass: "notification-error"
              });
              this.disabledButton = false;
              this.inProgress = false;
            },
            complete: () => {}
          })
        } else {
          this.showNotif = true;
          this.showNotifText = 'Error Input, Mohon check jumlah deposit anda';
          this.disabledButton = false;
        }
      }

  setDepositAuto() {
    if (this.isCorrectValue === 0 && (this.inputAmount * 1000) >= 10000 && (this.inputAmount * 1000) <= 10000000) {
      this.disabledButton = true;
      this.inProgress = true;
      let promoID = '';
      let promoType = '';
      if (this.currentPromo['_id']) {
        promoID = this.currentPromo['_id'];
        promoType = this.currentPromo['type'];
      }
      this.request.setTransactionAutoDeposit(this.auth.sessionValue, 'qris', (this.inputAmount * 1000), promoType, promoID).subscribe({
        next: (val) => {
          if (val['code'] === 200) {
            console.log(val);
            this.autoData = val['data'];
            this.autoData['amount'] = parseInt(this.autoData['amount'])
            this.autoData['transaction_fee'] = parseInt(this.autoData['transaction_fee']);
            this.statusPage = 'confirm';
            this.countTime();
            this.checkIntervalStatusDeposit();
            this.inProgress = false;
          }
        },
        error: (e) => {
          console.log(e);
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: { message: e.error.messages[0].message, type: 'error' },
            duration: 3000,
            verticalPosition: 'top',
            panelClass: "notification-error"
          });
          this.disabledButton = false;
          this.inProgress = false;
        },
        complete: () => { }
      })
    } else {
      let messageError = 'Nominal Harus Kelipatan 1.000';
      if ((this.inputAmount * 1000) < 10000) {
        messageError = "Minimal Jumlah Deposit Rp 10.000";
      }
      if ((this.inputAmount * 1000) > 10000000) {
        messageError = "Maksimum Jumlah Deposit Rp 10.000.000";
      }
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: { message: messageError, type: 'error' },
        duration: 3000,
        verticalPosition: 'top',
        panelClass: "notification-error"
      });
    }
  }

  backToMainDeposit() {
    this.inputAmount = undefined;
    this.currentNominal = 0;
    this.showError = false;
    // this.showPromo = false;
    this.statusPage = 'form';
  }

  cancelAutoPayment() {
    this.disabledButtonSub = true;
    this.request.cancelTransactionAutoDetail(this.auth.sessionValue, this.autoData['_id']).subscribe({
      next: (val) => {
        if (val['code'] === 200) {
          // console.log(val);
          // this.checkActiveDepositAuto();
          this.backToMainDeposit();
          this.checkActiveDepositAuto();
          this.disabledButtonSub = false;
        }
      },
      error: (e) => {
        console.log(e);
        // this.snackBar.openFromComponent(SnackbarComponent, {
        //   data: { message: e.error.messages[0].message, type: 'error' },
        //   duration: 3000,
        //   verticalPosition: 'top',
        //   panelClass: "notification-error"
        // });
        this.backToMainDeposit();
        this.checkActiveDepositAuto();
        this.disabledButtonSub = false
      },
      complete: () => { }
    })
  }

  checkActiveDepositAuto() {
    this.loader = true;
    if (this.intervalStatus) {
      clearInterval(this.intervalStatus)
    }
    this.request.checkActiveTransactionAuto(this.auth.sessionValue).subscribe({
      next: (val) => {
        if (val['code'] === 200) {
          // console.log(val);
          if (val['data']) {
            this.autoData = val['data'];
            this.autoData['amount'] = parseInt(this.autoData['amount']);
            this.autoData['transaction_fee'] = parseInt(this.autoData['transaction_fee']);

            this.statusPage = 'confirm';
            this.checkIntervalStatusDeposit();
            this.countTime();
          } else {
            this.checkActiveDepositManual();
            // this.statusPage = 'form'
            // if (this.intervalStatus) {
            //   clearInterval(this.intervalStatus);
            // }
          }
          this.loader = false;
        }
      },
      error: (e) => {
        console.log(e);
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: { message: e.error.messages[0].message, type: 'error' },
          duration: 3000,
          verticalPosition: 'top',
          panelClass: "notification-error"
        });
      },
      complete: () => { }
    })
  }

  backToTransaction(){
    this.router.navigate(['/']);
  }

  checkStatusDepositAuto() {
    this.disabledButtonSub = true;
    this.request.checkTransactionAuto(this.auth.sessionValue, this.autoData['_id']).subscribe({
      next: (val) => {
        if (val['code'] === 200) {
          console.log(val);
          this.disabledButtonSub = false
          if (val['data']['status'] === 2) {
            this.statusPage = 'success';
            if (this.intervalStatus) {
              clearInterval(this.intervalStatus);
            }
            this.countDown();
          }
        }
      },
      error: (e) => {
        console.log(e);
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: { message: e.error.messages[0].message, type: 'error' },
          duration: 3000,
          verticalPosition: 'top',
          panelClass: "notification-error"
        });
        this.disabledButtonSub = false
      },
      complete: () => { }
    })
  }

  countDown() {
    if (isPlatformBrowser(this.platformId)) {

      var x = setInterval(() => {
        this.timeCountSecond = this.timeCountSecond - 1;

        if (this.timeCountSecond < 0) {
          this.timeCountSecond = 0;
          clearInterval(x);
          this.timeCountSecond = 5;
          this.router.navigate(['/transaction'])
        }
      }, 1000);
    }
  }

  checkIntervalStatusDeposit() {
    if (isPlatformBrowser(this.platformId)) {
      this.intervalStatus = setInterval(() => {
        this.request.checkTransactionAuto(this.auth.sessionValue, this.autoData['_id']).subscribe({
          next: (val) => {
            if (val['code'] === 200) {
              // console.log(val);
              if (val['data']['status'] === 2) {
                this.statusPage = 'success';
                if (this.intervalStatus) {
                  clearInterval(this.intervalStatus);
                }
                this.countDown();
              }
            }
          },
          error: (e) => {
            console.log(e);
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: { message: e.error.messages[0].message, type: 'error' },
              duration: 3000,
              verticalPosition: 'top',
              panelClass: "notification-error"
            });
            this.disabledButtonSub = false
            if (this.intervalStatus) {
              clearInterval(this.intervalStatus);
            }
          },
          complete: () => { }
        })
      }, 15000);
    }
  }

  checkStatusDepositManual() {
    this.disabledButtonSub = true;
    this.request.checkActiveTransactionManual(this.auth.sessionValue).subscribe({
      next: (val) => {
        if (val['code'] === 200) {
          console.log(val);
          this.noteDeposit.setValue(val['data']['transaction_note']);
          this.disabledButtonSub = false
          if (val['data']['status'] === 2) {
            this.statusPage = 'success';
            if (this.intervalStatus) {
              clearInterval(this.intervalStatus);
            }
            this.countDown();
          }
        }
      },
      error: (e) => {
        console.log(e);
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: { message: e.error.messages[0].message, type: 'error' },
          duration: 3000,
          verticalPosition: 'top',
          panelClass: "notification-error"
        });
        this.disabledButtonSub = false
      },
      complete: () => { }
    })
  }

  checkActiveDepositManual() {
    this.loader = true;
    // if (this.intervalStatus) {
    //   clearInterval(this.intervalStatus)
    // }
    this.request.checkActiveTransactionManual(this.auth.sessionValue).subscribe({
      next: (val) => {
        if (val['code'] === 200) {
          console.log(val);
          if (val['data']) {
            this.manualData = val['data'];
            this.noteDeposit.setValue(val['data']['transaction_note']);
            //   this.autoData = val['data'];
            //   this.autoData['amount'] = parseInt(this.autoData['amount']);
            //   this.autoData['transaction_fee'] = parseInt(this.autoData['transaction_fee']);

            this.statusPage = 'confirm-bank'
            //   this.checkIntervalStatusDeposit();
            //   this.countTime();
          } else {
            this.statusPage = 'form';
            if (this.intervalStatus) {
              clearInterval(this.intervalStatus);
            }
          }
          this.loader = false;
        }
      },
      error: (e) => {
        console.log(e);
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: { message: e.error.messages[0].message, type: 'error' },
          duration: 3000,
          verticalPosition: 'top',
          panelClass: "notification-error"
        });
      },
      complete: () => { }
    })
  }

  goToTransaction() {
    this.router.navigate(['/transaction']);
  }

  onChangeURL(url: SafeUrl) {
    this.qrCodeDownloadLink = url;
  }

  countTime() {
    dayjs.extend(utc);
    const time1 = dayjs().utc().format('YYYY-MM-DD HH:mm:ss');
    const time2 = dayjs(this.autoData.paymentgateway_expired_at, 'YYYY-MM-DD HH:mm:ss');
    const diffInSeconds = time2.diff(time1, 'second');
    this.minutes = Math.floor(diffInSeconds / 60);
    this.seconds = diffInSeconds % 60;
    this.startCountdown();
  }

  checkCountdown() {
    this.minutesShow = String(this.minutes).padStart(2, '0');
    this.secondsShow = String(this.seconds).padStart(2, '0');
  }

  startCountdown() {
    this.intervalId = setInterval(() => {
      dayjs.extend(utc);
      const time1 = dayjs().utc().format('YYYY-MM-DD HH:mm:ss');
      const time2 = dayjs(this.autoData.paymentgateway_expired_at, 'YYYY-MM-DD HH:mm:ss');
      const diffInSeconds = time2.diff(time1, 'second');
      this.minutes = Math.floor(diffInSeconds / 60);
      this.seconds = diffInSeconds % 60;

      if (this.seconds <= 0) {
        if (this.minutes <= 0) {
          this.stopCountdown();
        } else {
          this.minutes--;
          this.seconds = 59;
          this.checkCountdown();
        }
      } else {
        this.seconds--;
        this.checkCountdown();
      }
    }, 1000);
  }

  stopCountdown() {
    this.minutesShow = "00";
    this.secondsShow = "00";
    this.showError = true;
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    if (this.intervalStatus) {
      clearInterval(this.intervalStatus);
    }
  }

  getBankCompany() {
    this.request.getBankCompanyList(this.auth.sessionValue).subscribe({
      next: (val) => {
        // console.log(val);
        if (val['code'] === 200) {
          if (val['data'][this.userGroupData['rank']] !== undefined) {
            this.bankCompanyList = val['data'][this.userGroupData['rank']];
          } else {
            this.bankCompanyList = val['data'][1];
          }
          // console.log(this.bankCompanyList);
        }
      },
      error: (err) => {
        console.log(err)
      },
      complete: () => { }
    })
  }

  updateNote() {
    if (isPlatformBrowser(this.platformId)) {
      if (this.typings) {
        clearTimeout(this.typings);
      }
      this.typings = setTimeout(() => {
        if (this.manualData) {
          let noteValue = this.noteDeposit.value;
          if (noteValue === null) {
            noteValue = '';
          }
          this.request.updateNoteDeposit(this.auth.sessionValue, this.manualData['_id'], noteValue).subscribe({
            next: (v) => {
              const value = v['data'] as Record<string, any>;
              if (v['code'] === 200) {
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: { message: 'Berhasil Update Catatan', type: 'mobile-default' },
                  duration: 3000,
                  horizontalPosition: "center",
                  verticalPosition: "top",
                  panelClass: "notification-success"
                });
              }
            },
            error: (e) => {
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: { message: e.error.messages[0].message, type: 'error' },
                duration: 3000,
                verticalPosition: 'top',
                panelClass: "notification-error"
              });
            },
            complete: () => { }
          })
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: { message: 'Catatan gagal di update', type: 'error' },
            duration: 3000,
            verticalPosition: 'top',
            panelClass: "notification-error"
          });
        }
      }, 2000);
    }
  }

  subscriptionData() {
    // this.subscription.add(this.route.queryParams.subscribe((params) => {
    //   if (params['p']) {
    //     this.paramsVal = params['p'];
    //   }
    //   if (params['p'] === '2') {
    //     this.currentTitle = 'Tambah Rekening';
    //     this.hideSwitchMenu = true;
    //     if (!this.showFormPaymentAuto && !this.showFormPaymentManual) {
    //       this.router.navigate(['/deposit'])
    //     }
    //   } else {
    //     this.currentTitle = 'Deposit';
    //     this.hideSwitchMenu = false;
    //     if (!this.showFormPaymentAuto && !this.showFormPaymentManual) {
    //       this.router.navigate(['/deposit'])
    //     }
    //   }
    // }));
  }

  getDepositConfig() {
    this.request.getDepositConfig(this.auth.sessionValue).subscribe({
      next: (val) => {
        if (val['code'] === 200) {
          console.log(val);
          this.depositConfig = val['data'] as Record<string, any>;
          this.setArrayNominal();
          // this.depositConfig['status'] = true;
          // this.depositConfig['qris'] = true;
          // this.depositConfig['virtual_account'] = true;
          if (this.depositConfig['status'] === false) {
            this.checkActiveDepositManual();
            // this.showFormPaymentManual = true;
            // this.loader = false;
            // this.currentOptionPayment = "manual";
            this.statusPage = "form";
            this.currentMethodPayment = "bank";
          } else {
            this.checkActiveDepositAuto();
            if ((this.depositConfig['virtual_account'] && !this.depositConfig['qris'])) {
              this.currentMethodPayment = 'vacc';
  
            }
            if (!this.depositConfig['virtual_account'] && this.depositConfig['qris']) {
              this.currentMethodPayment = 'qris'
  
            }
            if (!this.depositConfig['virtual_account'] && !this.depositConfig['qris']) {
              this.currentMethodPayment = 'bank'
  
            }
          }
          // this.loader =false;
        }
      },
      error: (err) => {
        console.log(err)
      },
      complete: () => { }
    })
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.getBankCompany();
      this.subscriptionData();
      this.getDepositConfig();
      this.getPromoList();

      let currentParam = this.route.snapshot.queryParamMap.get('p');
      if (currentParam) {
        this.router.navigate([this.router.url.split('?')[0]], { queryParams: { "p": null }, queryParamsHandling: 'merge' });
      }

      this.subscription.add(this.route.queryParams.subscribe((params) => {
        params;
        // console.log(params);
        if (params['p']) {
          this.paramsVal = params['p'];
        }
      }));
      // console.log(this.auth.userGorupData);
      this.userGroupData = this.auth.userGorupData;
      this.userProfile = this.auth.userProfileData;
      this.minDPAmount = this.data.transactionConfigJSONData['min_deposit_amount'];
      this.maxDPAmount = this.data.transactionConfigJSONData['max_deposit_amount'];
      for (const value of this.footerJSONData.records) {
        if (value.type === 'HOMEPAGE') {
          this.footerData = value.contents;
          break;
        }
      }
    }
  }

  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.subscription.unsubscribe();
    }
  }
}
