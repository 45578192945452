<div class="container-fluid register-component py-5" (click)="handleOutsideClick($event)">
  <ng-template #errorToastTpl let-message="message">
    {{ message }}
  </ng-template>

  <div class="row align-items-center main-register d-flex">
    <div class="h2 mb-4 text-white">DAFTAR</div>
    <div class="h4 mb-3 text-white fw-bold fs-15 text-left">INFORMASI PRIBADI</div>
    <form
      #registerForm="ngForm"
      (keydown.enter)="register(registerForm)"
      (ngSubmit)="register(registerForm)"
    >
      <div class="row form-row">
        <div class="col-sm-6 sub-col mt-2 mb-4">
          <div class="input-group border">
            <input
              type="text"
              class="form-control bg-transparent text-white border-right-0"
              placeholder="Nama Pengguna"
              [(ngModel)]="username"
              id="username"
              name="username"
              aria-label="Nama Pengguna"
              minlength="6"
              maxlength="16"
              (ngModelChange)="toUppercase($event, 'username')"
              (blur)="checkUsernameAvailability()"
              required
              #usernameInput="ngModel"
              autocomplete="off"
            />
            <span class="input-group-text bg-transparent border-0" tabindex="-1">
              <fa-icon [icon]="faUser" class="fa-1x"></fa-icon>
            </span>
          </div>
          <div class="text-danger" *ngIf="!isUsernameAvailable">
            Username is already taken.
          </div>
          <div
            *ngIf="
              usernameInput.invalid &&
              (usernameInput.dirty || usernameInput.touched)
            "
            class="text-danger"
          >
            <span *ngIf="usernameInput.errors?.['required']"
              >Username is required.</span
            >
            <span *ngIf="usernameInput.errors?.['minlength']"
              >Username must be at least 6 characters long.</span
            >
            <span *ngIf="usernameInput.errors?.['maxlength']"
              >Username cannot exceed 9 characters.</span
            >
          </div>
        </div>
        <div class="col-sm-6 sub-col mt-2 mb-4">
          <div class="input-group border">
            <input
              type="tel"
              id="phone"
              class="form-control bg-transparent text-white border-right-0"
              placeholder="Nomor Handphone"
              [(ngModel)]="mobilePhone"
              name="mobilePhone"
              aria-label="Nomor Handphone"
              oninput="this.value = this.value.replace(/^(\+.*\+)|[^0-9\+]/g, '');"
              #mobilePhoneInput="ngModel"
              required
              autocomplete="off"
            />
            <span class="input-group-text bg-transparent border-0">
              <fa-icon [icon]="faPhone" class="fa-1x"></fa-icon>
            </span>
          </div>
          <div
            *ngIf="
              mobilePhoneInput.invalid &&
              (mobilePhoneInput.dirty || mobilePhoneInput.touched)
            "
            class="text-danger"
          >
            <span *ngIf="mobilePhoneInput.errors?.['required']"
              >Mobile Phone is required.</span
            >
          </div>
        </div>
      </div>
      <div class="row form-row">
        <div class="col-sm-6 sub-col mb-4">
          <div class="input-group border">
            <input
              type="password"
              class="form-control bg-transparent text-white border-right-0"
              [type]="isPasswordVisible ? 'text' : 'password'"
              placeholder="Password"
              [(ngModel)]="password"
              name="password"
              aria-label="Password"
              required
              minlength="6"
              #passwordInput="ngModel"
              autocomplete="off"
            />
            <button
              class="btn btn-outline-secondary text-white border-0 outline-0"
              type="button" tabindex="-1"
              (click)="toggleVisibility('password')"
            >
              <fa-icon
                [icon]="isPasswordVisible ? faEyeSlash : faEye"
                class="fa-1x"
              ></fa-icon>
            </button>
          </div>
          <div
            *ngIf="
              passwordInput.invalid &&
              (passwordInput.dirty || passwordInput.touched)
            "
            class="text-danger"
          >
            <span *ngIf="passwordInput.errors?.['required']">
              Password is required
            </span>
            <span *ngIf="passwordInput.errors?.['minlength']"
              >Password must be at least 6 characters long.</span
            >
          </div>
          <div *ngIf="!checkPasswordMatch()" class="text-danger">
            Password and Confirm Password do not match.
          </div>
        </div>
        <div class="col-sm-6 sub-col mb-4">
          <div class="input-group border">
            <input
              type="password"
              class="form-control bg-transparent text-white border-right-0"
              [type]="isConfirmPasswordVisible ? 'text' : 'password'"
              placeholder="Confirm Password"
              [(ngModel)]="confirmPassword"
              name="confirmPassword"
              aria-label="Confirm Password"
              #confirmPasswordInput="ngModel"
              autocomplete="off"
              required
            />
            <button
              class="btn btn-outline-secondary text-white border-0 outline-0"
              type="button" tabindex="-1"
              (click)="toggleVisibility('confirmPassword')"
            >
              <fa-icon
                [icon]="isConfirmPasswordVisible ? faEyeSlash : faEye"
                class="fa-1x"
              ></fa-icon>
            </button>
          </div>
          <div
            *ngIf="
              confirmPasswordInput.invalid &&
              (confirmPasswordInput.dirty || confirmPasswordInput.touched)
            "
            class="text-danger"
          >
            <span *ngIf="confirmPasswordInput.errors?.['required']"
              >Confirm Password is required.</span
            >
          </div>
          <div *ngIf="!checkPasswordMatch()" class="text-danger">
            Password and Confirm Password do not match.
          </div>
        </div>
      </div>
      @if(websiteJSONData.configuration.pin){
        <div class="row form-row">
          <div class="col-sm-6 sub-col mb-4">
            <div class="input-group border">
              <input
                type="password"
                class="form-control bg-transparent text-white border-right-0"
                [type]="isPinVisible ? 'tel' : 'password'"
                placeholder="Create PIN"
                [(ngModel)]="pin"
                aria-label="Create PIN"
                name="pin"
                oninput="this.value = this.value.replace(/[^0-9]/g,'');"
                minlength="6"
                maxlength="16"
                #pinInput="ngModel"
                autocomplete="off"
                required
              />
              <button
                class="btn btn-outline-secondary text-white border-0 outline-0"
                type="button" tabindex="-1"
                (click)="toggleVisibility('pin')"
              >
                <fa-icon
                  [icon]="isPinVisible ? faEyeSlash : faEye"
                  class="fa-1x"
                ></fa-icon>
              </button>
            </div>
  
            <div
              *ngIf="pinInput.invalid && (pinInput.dirty || pinInput.touched)"
              class="text-danger"
            >
              <span *ngIf="pinInput.errors?.['required']" class="text-danger"
                >PIN is required.</span
              >
              <span *ngIf="pinInput.errors?.['minlength']"
                >PIN must be 6 characters long.</span
              >
            </div>
            <div *ngIf="!checkPinMatch()" class="text-danger">
              PIN and Confirm PIN do not match.
            </div>
          </div>
          <div class="col-sm-6 sub-col mb-4">
            <div class="input-group border">
              <input
                type="password"
                class="form-control bg-transparent text-white border-right-0"
                [type]="isConfirmPinVisible ? 'tel' : 'password'"
                placeholder="Confirm PIN"
                [(ngModel)]="confirmPin"
                name="confirmPin"
                aria-label="Confirm PIN"
                required
                oninput="this.value = this.value.replace(/[^0-9]/g,'');"
                maxlength="6"
                #confirmPinInput="ngModel"
                autocomplete="off"
              />
              <button
                class="btn btn-outline-secondary text-white border-0 outline-0"
                type="button" tabindex="-1"
                (click)="toggleVisibility('confirmPin')"
              >
                <fa-icon
                  [icon]="isConfirmPinVisible ? faEyeSlash : faEye"
                  class="fa-1x"
                ></fa-icon>
              </button>
            </div>
            <div
              *ngIf="
                confirmPinInput.invalid &&
                (confirmPinInput.dirty || confirmPinInput.touched)
              "
              class="text-danger"
            >
              <span *ngIf="confirmPinInput.errors?.['required']"
                >Confirm PIN is required.</span
              >
            </div>
            <div *ngIf="!checkPinMatch()" class="text-danger">
              PIN and Confirm PIN do not match.
            </div>
          </div>
        </div>
      }
      <div class="h4 mb-3 text-white fw-bold fs-15 text-left mt-4">
        INFORMASI AKUN
      </div>
      <div class="row form-row">
        <div class="col-sm-6 sub-col mt-2 mb-4">
          <!-- <div
            class="input-group input-group-dropdown border"
            (click)="toggleDropdown($event)"
          >
            <input
              type="text"
              class="form-control bg-transparent text-white border-right-0"
              placeholder="Pilih Bank"
              (keyup)="onSearchDropdownValue($event)"
              [value]="selectedOption"
              autocomplete="off"
            />
            <div
              class="dropdown-selector"
              [style.display]="isDropdownVisible ? 'block' : 'none'"
            >
              <div
                class="dropdown-selector-option"
                *ngFor="let option of searchedOptions"
                (click)="onSelectDropdownValue(option.name)"
              >
                {{ option.name }}
              </div>
            </div>
            <span class="input-group-text bg-transparent border-0">
              <fa-icon [icon]="faCaretDown" class="fa-lg"></fa-icon>
            </span>
          </div> -->

          <div class="input-group input-group-dropdown border mb-3">
            <div ngbDropdown class="d-inline-block w-100">
              <button type="button" class="btn btn-outline-primary w-100 btn-trigger" id="chooseBank" ngbDropdownToggle>
                @if(currentSelectedCreateBank['images']){
                  <span class="img">
                    <img [src]="currentSelectedCreateBank['images']['small']" [alt]="currentSelectedCreateBank['name']">
                  </span>
                }
                @if(currentSelectedCreateBank['name']){
                  <span id="bank_name" class="bank-name">{{currentSelectedCreateBank['name'] === "OTHER BANK" ? 'BANK LAINNYA': currentSelectedCreateBank['name']}}</span>
                } @else{
                  <span class="bank-name empty">{{'Pilih Bank'}}</span>
                }
              </button>
              <div ngbDropdownMenu class="w-100" aria-labelledby="chooseBank">
                <div class="w-100 search-create-bank">
                  <input type="text" class="form-control" autocomplete="off" [(ngModel)]="inputSearchCreate"
                    (keyup)="onSearchcreateValue($event)" placeholder="Search Bank.." name="searchBankInput"
                    aria-label="Search Bank.." />
                  <button type="button" class="btn">
                    <fa-icon [icon]="faMagnifyingGlass"></fa-icon>
                  </button>
                </div>
                @for (item of searchedCreateOptions; track item;) {
                <button type="button" ngbDropdownItem (click)="selectedCreateBank(item)">
                  <span class="img">
                    <img [src]="item['images']['small']" [alt]="item['name']">
                  </span>
                  <span class="bank-name">{{item['name'] === "OTHER BANK" ? 'BANK LAINNYA': item['name']}}</span>
                  @if(currentSelectedCreateBank['_id'] === item['_id']){
                  <fa-icon class="icon-right" [icon]="faCircleCheck"></fa-icon>
                  }
                </button>
                }
              </div>
            </div>
          </div>

          <div *ngIf="showBankNotification" class="text-danger">
            <span>Please select a bank.</span>
          </div>
        </div>
        <div class="col-sm-6 sub-col mt-2 mb-4">
          <div class="input-group border">
            <input
              type="tel"
              id="bank_number"
              class="form-control bg-transparent text-white border-right-0"
              placeholder="Nomor Rekening"
              [(ngModel)]="account_number"
              name="account_number"
              maxlength="20"
              aria-label="Nomor Rekening"
              oninput="this.value = this.value.replace(/[^0-9]/g,'');"
              maxlength="20"
              required
              #accountNumberInput="ngModel"
              autocomplete="off"
            />
            <span class="input-group-text bg-transparent border-0">
              <fa-icon [icon]="faBank" class="fa-1x"></fa-icon>
            </span>
          </div>

          <div
            *ngIf="
              accountNumberInput.invalid &&
              (accountNumberInput.dirty || accountNumberInput.touched)
            "
            class="text-danger"
          >
            <span *ngIf="accountNumberInput.errors?.['required']"
              >Account number is required.</span
            >
          </div>
        </div>
      </div>
      <div class="row form-row">
        <div class="col-sm-6 sub-col mt-2 mb-4">
          <div class="input-group border">
            <input
              type="text"
              id="account_name"
              class="form-control bg-transparent text-white border-right-0"
              placeholder="Nama Rekening"
              [(ngModel)]="account_name"
              name="account_name"
              aria-label="Nama Rekening"
              #accountNameInput="ngModel"
              autocomplete="off"
              required
            />
            <span class="input-group-text bg-transparent border-0">
              <fa-icon [icon]="faBank" class="fa-1x"></fa-icon>
            </span>
          </div>
          <div
            *ngIf="
              accountNameInput.invalid &&
              (accountNameInput.dirty || accountNameInput.touched)
            "
            class="text-danger"
          >
            <span *ngIf="accountNameInput.errors?.['required']"
              >Account name is required.</span
            >
          </div>
        </div>
        <div class="col-sm-6 sub-col mt-2 mb-4">
          <div class="input-group border">
            <input
              type="text"
              id="referral"
              class="form-control bg-transparent text-white border-right-0"
              placeholder="REFERRAL"
              [(ngModel)]="referral"
              name="referral"
              aria-label="REFERRAL"
              (ngModelChange)="toUppercase($event, 'referral')"
              [disabled]="isReferralDisabled"
              autocomplete="off"
            />
            <span class="input-group-text bg-transparent border-0">
              <fa-icon [icon]="faUserGroup" class="fa-1x"></fa-icon>
            </span>
          </div>
        </div>
      </div>
      <div class="col-md-6 my-2 mx-auto">
        @if (websiteJSONData.configuration.captcha) {
          <div class="input-captcha border">
            <label for="captcha" class="input-captcha-text bg-transparent border-0">
              <div class="img-captcha" [innerHTML]="imgCaptcha"></div>
            </label>
            <input id="captcha" type="text" style="text-align: center;" class="form-control bg-transparent text-white border-right-0" placeholder="Masukkan Kode"
              [(ngModel)]="captcha" name="captcha" aria-label="captcha" />
          </div>
        }
        <button type="submit" id="register-button" class="btn btn-register track-register mt-5" [disabled]="disableRegister">DAFTAR</button>
      </div>
    </form>
  </div>
  <!-- <div class="row align-items-center main-register-mobile d-flex d-md-none pb-4" >
    <form
      #registerForm="ngForm"
      (keydown.enter)="register(registerForm)"
      (ngSubmit)="register(registerForm)"
      class="mt-4"
    >
      <div class="input-group border-bottom mb-3">
        <input
          type="text"
          class="form-control bg-transparent text-white border-right-0"
          placeholder="Nama Pengguna"
          [(ngModel)]="username"
          name="username"
          aria-label="Nama Pengguna"
          autocomplete="off"
        />
        <span class="input-group-text bg-transparent border-0">
          <fa-icon [icon]="faUser" class="fa-1x"></fa-icon>
        </span>
      </div>
      <div class="input-group border-bottom mb-3">
        <input
          type="tel"
          class="form-control bg-transparent text-white border-right-0"
          placeholder="Nomor Handphone"
          [(ngModel)]="mobilePhone"
          name="mobilePhone"
          aria-label="Nomor Handphone"
          oninput="this.value = this.value.replace(/^(\+.*\+)|[^0-9\+]/g, '');"
          #mobilePhoneInput="ngModel"
          autocomplete="off"
          required
        />
        <span class="input-group-text bg-transparent border-0">
          <fa-icon [icon]="faPhone" class="fa-1x"></fa-icon>
        </span>
      </div>

      <div class="input-group border-bottom mb-3">
        <input
          type="password"
          class="form-control bg-transparent text-white border-right-0"
          [type]="isPasswordVisible ? 'text' : 'password'"
          placeholder="Password"
          [(ngModel)]="password"
          name="password"
          aria-label="Password"
          autocomplete="off"
        />
        <button
          class="btn btn-outline-secondary text-white border-0 outline-0"
          type="button"
          (click)="toggleVisibility('password')"
        >
          <fa-icon
            [icon]="isPasswordVisible ? faEyeSlash : faEye"
            class="fa-1x"
          ></fa-icon>
        </button>
      </div>
      <div class="input-group border-bottom mb-3">
        <input
          type="password"
          class="form-control bg-transparent text-white border-right-0"
          [type]="isConfirmPasswordVisible ? 'text' : 'password'"
          placeholder="Confirm Password"
          [(ngModel)]="confirmPassword"
          name="confirmPassword"
          aria-label="Confirm Password"
          #confirmPasswordInput="ngModel"
          autocomplete="off"
          required
        />
        <button
          class="btn btn-outline-secondary text-white border-0 outline-0"
          type="button"
          (click)="toggleVisibility('confirmPassword')"
        >
          <fa-icon
            [icon]="isConfirmPasswordVisible ? faEyeSlash : faEye"
            class="fa-1x"
          ></fa-icon>
        </button>
      </div>
      <div class="input-group border-bottom mb-3">
        <input
          type="password"
          class="form-control bg-transparent text-white border-right-0"
          [type]="isPinVisible ? 'tel' : 'password'"
          placeholder="Create PIN"
          [(ngModel)]="pin"
          aria-label="Create PIN"
          name="pin"
          oninput="this.value = this.value.replace(/[^0-9]/g,'');"
          minlength="6"
          maxlength="6"
          #pinInput="ngModel"
          autocomplete="off"
          required
        />
        <button
          class="btn btn-outline-secondary text-white border-0 outline-0"
          type="button"
          (click)="toggleVisibility('pin')"
        >
          <fa-icon
            [icon]="isPinVisible ? faEyeSlash : faEye"
            class="fa-1x"
          ></fa-icon>
        </button>
      </div>
      <div class="input-group border-bottom mb-3">
        <input
          type="password"
          class="form-control bg-transparent text-white border-right-0"
          [type]="isConfirmPinVisible ? 'tel' : 'password'"
          placeholder="Confirm PIN"
          [(ngModel)]="confirmPin"
          name="confirmPin"
          aria-label="Confirm PIN"
          required
          oninput="this.value = this.value.replace(/[^0-9]/g,'');"
          maxlength="6"
          #confirmPinInput="ngModel"
          autocomplete="off"
        />
        <button
          class="btn btn-outline-secondary text-white border-0 outline-0"
          type="button"
          (click)="toggleVisibility('confirmPin')"
        >
          <fa-icon
            [icon]="isConfirmPinVisible ? faEyeSlash : faEye"
            class="fa-1x"
          ></fa-icon>
        </button>
      </div>
      <div
        class="input-group input-group-dropdown border-bottom mb-3"
        (click)="toggleDropdown($event)"
      >
        <input
          type="text"
          class="form-control bg-transparent text-white border-right-0"
          placeholder="Pilih Bank"
          (keyup)="onSearchDropdownValue($event)"
          [value]="selectedOption"
          autocomplete="off"
        />
        <div
          class="dropdown-selector"
          [style.display]="isDropdownVisible ? 'block' : 'none'"
        >
          <div
            class="dropdown-selector-option"
            *ngFor="let option of searchedOptions"
            (click)="onSelectDropdownValue(option.name)"
          >
            {{ option.name }}
          </div>
        </div>
        <span class="input-group-text bg-transparent border-0">
          <fa-icon [icon]="faCaretDown" class="fa-lg"></fa-icon>
        </span>
      </div>
      <div class="input-group border-bottom mb-3">
        <input
          type="tel"
          class="form-control bg-transparent text-white border-right-0"
          placeholder="Nomor Rekening"
          [(ngModel)]="account_number"
          name="account_number"
          aria-label="Nomor Rekening"
          oninput="this.value = this.value.replace(/[^0-9]/g,'');"
          required
          #accountNumberInput="ngModel"
          autocomplete="off"
        />
        <span class="input-group-text bg-transparent border-0">
          <fa-icon [icon]="faBank" class="fa-1x"></fa-icon>
        </span>
      </div>
      <div class="input-group border-bottom mb-3">
        <input
          type="text"
          class="form-control bg-transparent text-white border-right-0"
          placeholder="Nama Rekening"
          [(ngModel)]="account_name"
          name="account_name"
          aria-label="Nama Rekening"
          #accountNameInput="ngModel"
          autocomplete="off"
          required
        />
        <span class="input-group-text bg-transparent border-0">
          <fa-icon [icon]="faBank" class="fa-1x"></fa-icon>
        </span>
      </div>
      <div class="input-group border-bottom mb-3">
        <input
          type="text"
          class="form-control bg-transparent text-white border-right-0"
          placeholder="REFERRAL"
          [(ngModel)]="referral"
          name="referral"
          aria-label="REFERRAL"
          (ngModelChange)="toUppercase($event, 'referral')"
          [disabled]="isReferralDisabled"
          autocomplete="off"
        />
        <span class="input-group-text bg-transparent border-0">
          <fa-icon [icon]="faUserGroup" class="fa-1x"></fa-icon>
        </span>
      </div>
      <button
        type="submit"
        class="fixed-bottom text-uppercase border-0 outline-0 text-white w-100 btn-sign-mobile"
      >
        daftar
      </button>
    </form>
  </div> -->
  <app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
</div>
