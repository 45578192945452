import { CommonModule, DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID, ViewChild } from '@angular/core';
import { FooterComponent } from '@template/green/footer/footer.component';
import { AllGamesComponent } from '@template/green/parts/all-games/all-games.component';
import { DataService } from '@componentpath/services/data.service';
import { SidebarComponent } from '@template/green/parts/sidebar/sidebar.component';
import { Slideshow1Component } from '@template/green/parts/slideshow1/slideshow1.component';
import { HeaderSearchComponent } from '@template/green/header-search/header-search.component';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { HeaderMobileComponent } from '@template/green/mobile/header-mobile/header-mobile.component';
import { MatDrawer, MatDrawerContainer, MatDrawerMode, MatSidenavModule } from '@angular/material/sidenav';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Subscription } from 'rxjs';
import { AuthService } from '@componentpath/services/auth.service';
import { faAngleRight, faComments, faGift, faHouse, faMoneyBill, faRightToBracket, faUser, faWallet } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faAndroid } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-search',
  standalone: true,
  templateUrl: './search.component.html',
  styleUrl: './search.component.scss',
  imports: [
    CommonModule,
    HeaderSearchComponent,
    FooterComponent,
    AllGamesComponent,
    SidebarComponent,
    Slideshow1Component,
    HeaderMobileComponent,
    FontAwesomeModule,
    RouterModule,
    MatSidenavModule
  ],
})
export class SearchComponent {
  @ViewChild(MatDrawer)
  drawer!: MatDrawer;
  websiteJSONData: any = this.data.websiteJSONData;
  subscription: Subscription = new Subscription();
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private doc: Document,
    public data: DataService,
    private breakpointObserver: BreakpointObserver,
    private route: ActivatedRoute,
    private auth: AuthService
  ) {
    this.checkScreen();
  }

  faAngleRight: IconProp = faAngleRight;
  faMoneyBill: IconProp = faMoneyBill;
  faWallet: IconProp = faWallet;
  faAndroid: IconProp = faAndroid;
  faGift: IconProp = faGift;
  faHouse: IconProp = faHouse;
  faComments: IconProp = faComments;
  faRightToBracket: IconProp = faRightToBracket;
  faUser: IconProp = faUser;
  livechatURL: string = '';

  currentScreenSize: string = '';

  slideshowJSONData: any = this.data.categoryslideshowJSONData;
  slideshowData: any = [];

  currentSideBarMode: MatDrawerMode = "side";

  isSideNavOpened: any = true;

  showSideBar: boolean = false;
  isLoggedin: boolean = false;

  currentParamsCat :any;
  currentParamsTag :any;
  sourceAPK: string = 'desktop';

  checkScreen() {
    let displayNameMap = new Map([
      [Breakpoints.XSmall, 'xSmall'],
      ["(min-width: 600px) and (max-width: 799.98px)", 'smallOne'],
      ["(min-width: 800px) and (max-width: 959.98px)", 'smallTwo'],
      ["(min-width: 960px) and (max-width: 1365.98px)", 'customTablet'],
    ]);
    this.breakpointObserver.observe([
      Breakpoints.XSmall,
      "(min-width: 600px) and (max-width: 799.98px)",
      "(min-width: 800px) and (max-width: 959.98px)",
      "(min-width: 960px) and (max-width: 1365.98px)",
      "(min-width: 1366px)"
    ]).subscribe(result => {
      // console.log(result)
      for (const query of Object.keys(result.breakpoints)) {
        if (result.breakpoints[query]) {
          this.currentScreenSize = displayNameMap.get(query) ?? 'desktop';
          if (this.currentScreenSize === 'xSmall') {
            this.currentSideBarMode = "over";
          }
          if (this.currentScreenSize === 'smallOne') {
            this.currentSideBarMode = "over";
          }
          if (this.currentScreenSize === 'smallTwo') {
            this.currentSideBarMode = "side";
            this.isSideNavOpened = true;
          }
          if (this.currentScreenSize === 'customTablet') {
            this.currentSideBarMode = "side";
            this.isSideNavOpened = true;
          }
          if (this.currentScreenSize === 'desktop') {
            this.currentSideBarMode = "side";
            this.isSideNavOpened = true;
          }
        }
      }
    });
  }

  openedSideNav(){
    // this.isSideNavOpened = true;
  }
  closedSideNav(){
    // this.isSideNavOpened = false;
  }

  backdropClick(){
    this.drawer.close();
    this.currentSideBarMode = 'side';
    this.isSideNavOpened = false;
    setTimeout(() => {
      this.drawer.open();
    }, 500)
  }
  showClick(){
    this.showSideBar = true;
  }
  hideClick(){
    this.showSideBar = false;
  }

  checkParams(){
    if (this.route.snapshot.url[0].path === 'game' || this.route.snapshot.url[0].path === 'real-money') {
      if (this.route.snapshot.paramMap.get('type')) {
        this.currentParamsCat = undefined;
        
        let type = this.route.snapshot.paramMap.get('type')
        if (type === 'livecasino' || type === 'live-casino') {
          type = 'LC';
        } else if (type === 'shooting' || type === 'arcade') {
          type = 'FH'
        } else if (type === 'sportbook') {
          type = 'SB'
        } else if (type === 'esport') {
          type = 'ES'
        } else if (type === 'crash' || type == 'crash-game') {
          type = 'CR'
        } else {
          type = 'SL'
        }
        this.currentParamsCat = type;
        // console.log(this.currentParamsCat)
      }
      // console.log(this.currentParamsCat)
    } else {
      let paramsDoc = new URL(document.location.toString()).searchParams;
      let typesDoc = paramsDoc.get("categories");
      let tagsDoc = paramsDoc.get("tags");
      this.currentParamsTag = undefined;
      this.currentParamsCat = undefined;
  
      if (tagsDoc) {
        let arrTags = JSON.parse(decodeURIComponent(tagsDoc));
        if (arrTags.length === 1) {
          this.currentParamsTag = arrTags[0];
        } else {
          this.currentParamsTag = undefined;
        }
      }
  
      if (typesDoc) {
        let arrTypes = JSON.parse(decodeURIComponent(typesDoc));
        if (arrTypes.length === 1 && this.currentParamsTag === undefined) {
          this.currentParamsCat = arrTypes[0];
        } else {
          this.currentParamsCat = undefined;
        }
      }
    }
    // console.log(this.currentParamsTag)
    // console.log(this.currentParamsCat)
  
  }

  public setLivechatURL() {
    const livechatData = this.websiteJSONData['live_chat'];
    let engineName = (livechatData['engine']).toLowerCase();
    if (engineName === 1 || engineName === 'livechatinc' || engineName === 'livechat' || engineName === 'livechat.com') {
      if (livechatData['code']) {
        this.livechatURL = "https://direct.lc.chat/" + livechatData['code'] + "/" + livechatData['group'];
      }
    }
    if (engineName === 2 || engineName === 'purechat') {
      this.livechatURL = "https://app.purechat.com/w/" + livechatData['pagename'];
    }
    if (engineName === 3 || engineName === 'tawk.to') {
      if (livechatData['pagename']) {
        this.livechatURL = "https://tawk.to/" + livechatData['pagename'];
      } else {
        this.livechatURL = "https://tawk.to/chat/" + livechatData['code'];
      }
    }
  }

  subscriptionData() {
    this.subscription.add(
      this.auth.getLoginStatus.subscribe({
        next: (v) => {
          this.isLoggedin = v;
        },
      })
    );
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.checkParams();
      this.subscriptionData();
      this.setLivechatURL();
      // this.route.queryParams.subscribe((params) => {
      //   let types = JSON.parse(decodeURIComponent(params['categories']));
      //   let tags = JSON.parse(decodeURIComponent(params['tags']));
      //   if (tags.length === 1) {
      //     this.currentParamsTag = tags[0];
      //   } else {
      //     this.currentParamsTag = undefined;
      //   }
      //   if(types.length === 1){
      //     console.log(tags.length)
      //     console.log(this.currentParamsTag)
      //     if (tags.length === 0 || this.currentParamsTag === undefined){
      //       console.log('eeeeeeeeee')
      //       this.currentParamsCat = types[0];
      //     } else{
      //       this.currentParamsCat = undefined;
      //     }
      //   } else{
      //     this.currentParamsCat = undefined;
      //   }
      //   console.log(types)
      //   console.log(this.currentParamsCat)
      // });
      setTimeout(() => {
        this.doc.body.scrollTo(0, 0) //temporary deleted soon
      }, 200);
      // for (const value of this.slideshowJSONData) {
      //   if (!value.global) {
      //     this.slideshowData.push(value);
      //   }
      // }
      this.slideshowData = this.slideshowJSONData;
    }
  }
  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.subscription.unsubscribe();
      // window.removeEventListener('scroll', this.scroll, true);
    }
  }
}
