import { Component, CUSTOM_ELEMENTS_SCHEMA, ElementRef, HostListener, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { CommonModule, CurrencyPipe, DOCUMENT, isPlatformBrowser } from '@angular/common';
import { HeaderComponent } from "@template/green/header/header.component";
import { FooterComponent } from '@template/green/footer/footer.component';
import { DataService } from '@componentpath/services/data.service';
import { AuthService } from '@componentpath/services/auth.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { faCaretDown, faCircle, faCircleCheck, faCircleInfo, faCirclePlus, faCircleXmark, faCreditCard, faMagnifyingGlass, faMoneyCheckDollar, faSliders, faUser, faWallet } from '@fortawesome/free-solid-svg-icons';
import { RequestService } from '@componentpath/services/request.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { SplitNumberPipe } from '@componentpath/pipes/format-number-id.pipe';
import { SwiperDirective } from '@componentpath/lib/swiper.directive';
import { SwiperOptions } from 'swiper/types';
import { A11y, Grid, Mousewheel, Navigation, Pagination, Scrollbar } from 'swiper/modules';
import { ActivatedRoute, Router } from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { WithdrawalMobileComponent } from '@template/green/mobile/withdrawal-mobile/withdrawal-mobile.component';
import { HeaderSimpleComponent } from '@template/green/header-simple/header-simple.component';
import { OrderByPipe } from '@componentpath/pipes/slug-name.pipe';

@Component({
  selector: 'app-withdrawal',
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, OrderByPipe, HeaderSimpleComponent, FooterComponent, FormsModule, CurrencyPipe, FontAwesomeModule, NgbDropdownModule, SwiperDirective, SplitNumberPipe, WithdrawalMobileComponent],
  templateUrl: './withdrawal.component.html',
  styleUrl: './withdrawal.component.scss'
})
export class WithdrawalComponent implements OnInit, OnDestroy{

  @ViewChild('swiper') swiper!: ElementRef<any>;

  subscription: Subscription = new Subscription();

  balance: number = 0;
  isLoggedin: boolean = false;
  footerJSONData: any = this.dataService.footerJSONData;
  footerData: any = [];
  optionsBank : Array<any> = [];
  searchedOptions : Array<any> = [];
  searchedCreateOptions : Array<any> = [];
  listSelectBankUser: Record<string, any>[] = [];
  listSelectBankUserBank: Record<string, any>[] = [];
  listSelectBankUserEwall: Record<string, any>[] = [];
  listSelectBankUserQris: Record<string, any>[] = [];
  listSelectBankUserCell: Record<string, any>[] = [];
  transactionConfig: Record<string, any> = {};

  listSelectCreateBank: Record<string, any>[] = [];
  listSelectCreateEwallet: Record<string, any>[] = [];

  currentSelectPaymentType: string = 'all';
  currentSelectCreateBank: string = 'bank';
  currentSelectedCreateBank: Record<string, any> = {};
  currentCreatedBank: Record<string, any> = {};
  isCreatedBank: boolean = false;
  livechatURL: string = '';

  currentDataBankUser: Record<string, any> = {};
  currentSelectDataBankUser: Record<string, any> = {};
  paymentType: string = '';
  showNotifText: string = '';
  searchBankInput: string = '';
  inputSearchCreate: string = '';
  inputUserBankNum: string = '';
  inputUserBankName: string = '';
  minWDAmount: number = 0;

  showSelectPayment: boolean = false;
  showCreateBank: boolean = false;
  disabledButton: boolean = false;
  showError: boolean = false;
  showNotif: boolean = false;
  loader: boolean = true;

  bankListA: Record<string, any>[] = [];
  bankListB: Record<string, any>[] = [];
  bankListC: Record<string, any>[] = [];
  bankListALL: Record<string, any>[] = [];
  bankList: Record<string, any>[] = [];
  bankListCreateA: Record<string, any>[] = [];
  bankListCreateC: Record<string, any>[] = [];
  bankListCreateALL: Record<string, any>[] = [];

  listSelectBankCompany: Record<string, any>[] = [];
  currentDataBankAdmin: Record<string, any> = {};
  currentFee: number = 0;

  inputAmount: any = null;

  faWallet = faWallet;
  faSliders = faSliders;
  faCaretDown = faCaretDown;
  faUser = faUser;
  faCreditCard = faCreditCard;
  faCircleXmark = faCircleXmark;
  faCircleInfo = faCircleInfo;
  faMoneyCheckDollar = faMoneyCheckDollar;
  faCircleCheck = faCircleCheck;
  faMagnifyingGlass = faMagnifyingGlass;
  faCirclePlus = faCirclePlus;

  loadingSubmit: boolean = false;
  valueKeyboard: string = '';
  showPin: boolean = false;

  @HostListener('document:keydown', ['$event'])
  keyboardKey(event: KeyboardEvent) {
    if (this.showPin) {
      this.valueKeyboard = event.key;
      const eventElement = event.target as Element;
      const numArr = eventElement.id.match(/\d+/g);
      let num = 0;
      if (numArr) {
        num = parseInt(numArr[0]);
      }
      const currentInput = this.documentHTML.getElementById(
        'pin' + num
      ) as HTMLInputElement;
      const prevInput = this.documentHTML.getElementById(
        'pin' + (num - 1)
      ) as HTMLInputElement;
      if (currentInput) {
        if (this.valueKeyboard === 'Tab' || this.valueKeyboard === 'Delete') {
          event.preventDefault();
        }
        if (
          (currentInput.value === '' || currentInput.value === undefined) &&
          prevInput &&
          this.valueKeyboard === 'Backspace'
        ) {
          event.preventDefault();
          prevInput.focus();
        }
      }
    }
  }

  formPin = {
    pina: '',
    pinb: '',
    pinc: '',
    pind: '',
    pine: '',
    pinf: '',
  };

  public config: SwiperOptions = {
    init: true,
    modules: [Navigation, Pagination, A11y, Mousewheel, Grid, Scrollbar],
    slidesPerView: 3,
    grabCursor: false,
    scrollbar: true,
    loop: false,
    freeMode: true,
    direction: 'vertical',
    mousewheel: {
      enabled: true,
    },
    pagination: false,
  };

  currentScreenSize: string = '';

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private documentHTML: Document,
    public dataService: DataService,
    private auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private requestService: RequestService,
    private breakpointObserver: BreakpointObserver
  )
  {
    this.checkScreen();
  }

  checkScreen() {
    this.breakpointObserver.observe([
      Breakpoints.Handset,
      Breakpoints.Tablet,
    ]).subscribe(result => {
      if (result.matches) {
        this.currentScreenSize = 'mobile';
        this.subscription.unsubscribe();
      }
      else {
        this.currentScreenSize = 'desktop';
      }
      // console.log(this.currentScreenSize)
    });
  }

  back() {
    if (isPlatformBrowser(this.platformId)) {
      history.back();
    }
  }

  inputPIN(i: number) {
    const nextIndex = i + 1;
    const prevIndex = i - 1;
    const currentInput = this.documentHTML.getElementById(
      'pin' + i
    ) as HTMLInputElement;
    const nextInput = this.documentHTML.getElementById(
      'pin' + nextIndex
    ) as HTMLInputElement;
    const prevInput = this.documentHTML.getElementById(
      'pin' + prevIndex
    ) as HTMLInputElement;
    const lastInput = this.documentHTML.getElementById(
      'pin' + 6
    ) as HTMLInputElement;
    if (
      nextInput &&
      currentInput.value !== '' &&
      this.valueKeyboard !== 'Backspace'
    ) {
      nextInput.focus();
    }
    if (prevInput && this.valueKeyboard === 'Backspace') {
      prevInput.focus();
    }
    if (
      currentInput === lastInput &&
      (currentInput.value !== '' || currentInput.value !== undefined) &&
      this.valueKeyboard !== 'Backspace'
    ) {
      // console.log(lastInput.value);
      this.formPin.pinf = lastInput.value;
    }

    if (i === 6) {
      this.submitPIN();
    }
    return;
  }

  checkPin() {
    this.formPin.pina = '';
    this.formPin.pinb = '';
    this.formPin.pinc = '';
    this.formPin.pind = '';
    this.formPin.pine = '';
    this.formPin.pinf = '';

    this.showNotif = false;
    this.showNotifText = '';
    this.showPin = true;
  }

  public setLivechatURL() {
    const livechatData = this.dataService.websiteJSONData['live_chat'];
    let engineName = (livechatData['engine']).toLowerCase();
    if (engineName === 1 || engineName === 'livechatinc' || engineName === 'livechat' || engineName === 'livechat.com') {
      if (livechatData['code']) {
        this.livechatURL = "https://direct.lc.chat/" + livechatData['code'] + "/" + livechatData['group'];
      }
    }
    if (engineName === 2 || engineName === 'purechat') {
      this.livechatURL = "https://app.purechat.com/w/" + livechatData['pagename'];
    }
    if (engineName === 3 || engineName === 'tawk.to') {
      if (livechatData['pagename']) {
        this.livechatURL = "https://tawk.to/" + livechatData['pagename'];
      } else {
        this.livechatURL = "https://tawk.to/chat/" + livechatData['code'];
      }
    }
  }

  selectedBankUser(val: any) {
    // this.currentDataBankUser = val;
    this.currentSelectDataBankUser = val;
    // if (this.listSelectBankCompany.length > 0) {
    //   let resultData = this.listSelectBankCompany.find((o: any) => o.system_bank.code === this.currentDataBankUser['system_bank']['code']);
    //   if (resultData === undefined) {
    //     this.currentDataBankAdmin = this.listSelectBankCompany[0];
    //   } else {
    //     this.currentDataBankAdmin = resultData;
    //   }
    // }
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  selectPayment() {
    this.currentDataBankUser = this.currentSelectDataBankUser;
    this.showSelectPayment = false;
    this.paymentType = '';
    if (this.listSelectBankCompany.length > 0) {
      let resultData = this.listSelectBankCompany.find((o: any) => o.system_bank.code === this.currentDataBankUser['system_bank']['code']);
      // console.log(resultData)
      if (resultData === undefined) {
        // this.currentDataBankAdmin = this.listSelectBankCompany[0];
        let isDefault = this.listSelectBankCompany.find((o: any) => o.group_default);
        if (isDefault) {
          this.currentDataBankAdmin = isDefault;
        } else {
          this.currentDataBankAdmin = this.listSelectBankCompany[0];
        }
      } else {
        this.currentDataBankAdmin = resultData;
      }
    }
  }

  selectBankOption(){
    this.showSelectPayment = true;
    this.showCreateBank = false;
    this.showNotif = false;
    this.router.navigate([this.router.url.split('?')[0]], { queryParams: { "p":1 }, queryParamsHandling: 'merge' });
    this.currentSelectDataBankUser = this.currentDataBankUser;
  }
  selectCreateBankOption(){
    this.showSelectPayment = false;
    this.showCreateBank = true;
    this.showNotif = false;
    this.router.navigate([this.router.url.split('?')[0]], { queryParams: { "p":2 }, queryParamsHandling: 'merge' });
  }

  buttonBack(){
    this.showError = false;
    this.showSelectPayment = false;
    this.paymentType = '';
  }

  selectBankCreate(e:string){
    this.currentSelectCreateBank = e;
    if(e === 'bank'){
      this.searchedCreateOptions = this.listSelectCreateBank;
    } else {
      this.searchedCreateOptions = this.listSelectCreateEwallet;
    }
    this.currentSelectedCreateBank = this.searchedCreateOptions[0];
    // console.log(this.searchedCreateOptions);
  }

  selectedCreateBank(e: any){
    this.currentSelectedCreateBank = e;
  }

  getBankCompany() {
    this.loader = true;
    this.bankListA = [];
    this.bankListC = [];
    this.bankListALL = [];
    let value = this.dataService.bankRegisterJSONData['records'] as any[];
    value.sort((a, b) => parseFloat(a['sort']) - parseFloat(b['sort']) || a["name"].localeCompare(b["name"]));
    this.bankListALL = value.filter((option) =>
      ((option['type'] === 1 || option['type'] === 2) && option['code'] !== 'oth' && option['status'])
    );
    this.bankListA = value.filter((option) =>
      ((option['type'] !== 1) && option['code'] !== 'oth' && option['status'])
    );
    this.bankListC = value.filter((option) =>
      ((option['type'] === 1) && option['code'] !== 'oth' && option['status'])
    );

    this.requestService.getBankPlayerList(this.auth.sessionValue, this.auth.userProfileData['_id'] as string).subscribe({
      next: (v) => {
        // console.log(v);
        const value = v as Record<string, any>;
        if (value['code'] === 200) {
          value['data']['records'] = value['data']['records'].filter((option: any) =>
            option['status']
          );
          this.bankListB = value['data']['records'];
          this.bankList = this.bankListB;
          // this.bankList.sort((a, b) => parseFloat(a['system_bank']['sort']) - parseFloat(b['system_bank']['sort']));
          this.bankListCreateA = this.bankListA.filter(({ _id: id1 }) => !this.bankList.some(({ bank: id2 }) => id2 === id1));
          this.bankListCreateC = this.bankListC.filter(({ _id: id1 }) => !this.bankList.some(({ bank: id2 }) => id2 === id1));
          this.bankListCreateALL = this.bankListALL.filter(({ _id: id1 }) => !this.bankList.some(({ bank: id2 }) => id2 === id1));
          this.bankListCreateC.sort(function (a: any, b: any) {
            return a.name.localeCompare(b.name)
          });
          this.bankListCreateALL.sort(function (a: any, b: any) {
            return a.name.localeCompare(b.name)
          });
          if (this.isCreatedBank) {
            let indexSelected = this.bankList.findIndex(x => (x['bank'] === this.currentCreatedBank['bank'] && x['account_number'] === this.currentCreatedBank['account_number']));
            this.currentDataBankUser = this.bankList[indexSelected];
          }else{
            if (this.bankList[0]['system_bank']['code'] !== 'qris') {
              this.currentDataBankUser = this.bankList[0];
            } else {
              this.currentDataBankUser = this.bankList[1];
            }
          }

          this.currentSelectDataBankUser = this.currentDataBankUser;
          // this.listSelectBankUser = this.bankList;
          this.listSelectBankUser = this.bankList.filter((option) =>
            option['system_bank']['type'] !== 3 && option['system_bank']['type'] !== 4
          );
          this.searchedOptions = this.listSelectBankUser;
          this.currentSelectedCreateBank = this.bankListCreateC[0];

          this.listSelectCreateBank = this.bankListCreateC.filter((option) =>
            option['type'] === 1
          );
          if (this.transactionConfig['allow_add_ewallet']) {
            this.listSelectCreateEwallet = this.bankListA.filter((option) =>
              option['type'] === 2
            );
          } else {
            this.listSelectCreateEwallet = this.bankListCreateA.filter((option) =>
              option['type'] === 2
            );
          }

          this.searchedCreateOptions = this.listSelectCreateBank;

          this.listSelectBankUserBank = this.bankList.filter((option) =>
            option['system_bank']['type'] === 1
          );
          this.listSelectBankUserEwall = this.bankList.filter((option) =>
            option['system_bank']['type'] === 2
          );
          // this.listSelectBankUserCell = this.bankList.filter((option) =>
          //   option['system_bank']['type'] === 3
          // );
          // this.listSelectBankUserQris = this.bankList.filter((option) =>
          //   option['system_bank']['type'] === 4
          // );
          // console.log(this.listSelectBankUserBank)
          // console.log(this.listSelectBankUserEwall)
          // console.log(this.listSelectBankUserCell)
          // console.log(this.listSelectBankUserQris)

          this.getBankCompanyList();
        }
      },
      error: (error) => {
        console.log(error)
      },
      complete: () => {
      }
    })
  }

  createBankAcc(){
      this.disabledButton = true;
    if (this.inputUserBankNum !== '' && this.inputUserBankNum !== undefined && this.inputUserBankName !== '' && this.inputUserBankName !== undefined) {

      this.showNotif = false;
      this.requestService.createAccountBank(this.auth.sessionValue, this.currentSelectedCreateBank['_id'], this.inputUserBankName as string, this.inputUserBankNum as string, this.auth.userProfileData['_id'] as string).subscribe({
          next: (v) => {
            const value = v as Record<string, unknown>;
            // console.log(value);
            if (value['code'] === 200) {
              const data = value['data'] as Record<string, unknown>;
              this.router.navigate(['/withdraw']);
              this.isCreatedBank = true;
              this.currentCreatedBank = data;
              this.getBankCompany();
              this.showCreateBank = false;
              this.disabledButton = false;
              // this.toastService.showToasts(value['messages'] as Record<string, string | number | boolean>[]);
              // this.dialogRef.close(value['data']);
            }
          },
          error: (e) => {
            console.log(e);
            this.disabledButton = false;
            if (e.error['messages']) {
              this.showNotif = true;
              this.showNotifText = e.error.messages[0].message;
              // this.toastService.showToasts(e.error['messages'] as Record<string, string | number | boolean>[])
            }
            else {
              // this.toastService.showToast({ message: 'Error communication with API - Please check your internet connection', status: 'error' });
            }
            this.disabledButton = false;
          },
          complete: () => { }

        })

      } else {
      this.showNotif = true;
      this.showNotifText = 'Error Input, Mohon Check Nomor Rekening Anda';
        this.disabledButton = false;
      }
  }

  onSearchcreateValue(e:Event){
    const input = this.inputSearchCreate.toLowerCase();
    let typeLists: Array<any> = [];

    if (this.currentSelectCreateBank === 'bank') {
      typeLists = this.listSelectCreateBank;
    }
    if (this.currentSelectCreateBank === 'ewallet') {
      typeLists = this.listSelectCreateEwallet;
    }

    this.searchedCreateOptions =  typeLists.filter((option) =>
      (option['name'].toLowerCase().includes(input))
    );

    if (!input) {
      this.searchedCreateOptions = [...typeLists];
    }
  }

  onSearchDropdownValue(event: Event): void {
    const input = this.searchBankInput.toLowerCase();
    let typeLists: Array<any> = [];
    if(this.currentSelectPaymentType === 'all'){
      typeLists = this.listSelectBankUser;
    }
    if(this.currentSelectPaymentType === 'bank'){
      typeLists = this.listSelectBankUserBank;
    }
    if(this.currentSelectPaymentType === 'ewallet'){
      typeLists = this.listSelectBankUserEwall;
    }
    if(this.currentSelectPaymentType === 'cellular'){
      typeLists = this.listSelectBankUserCell;
    }
    if(this.currentSelectPaymentType === 'qris'){
      typeLists = this.listSelectBankUserQris;
    }
    this.searchedOptions = typeLists.filter((option) =>
      (option['system_bank']['name'].toLowerCase().includes(input) || (option['account_number']).includes(input) || (option['account_name']).toLowerCase().includes(input))
    );

    if (!input) {
      this.searchedOptions = [...typeLists];
    }
  }

  onSelectDropdownValue(e: any): void {
    console.log(e);
    if (e === 'all') {
      this.searchedOptions = this.listSelectBankUser;
    }
    if (e === 'bank') {
      this.searchedOptions = this.listSelectBankUserBank;
    }
    if (e === 'ewallet') {
      this.searchedOptions = this.listSelectBankUserEwall;
    }
    if (e === 'cellular') {
      this.searchedOptions = this.listSelectBankUserCell;
    }
    if (e === 'qris') {
      this.searchedOptions = this.listSelectBankUserQris;
    }
    this.currentSelectPaymentType = e;
  }

  getBankCompanyList() {
    this.requestService.getBankCompanyAccount(this.auth.sessionValue, this.auth.userGorupData['_id']).subscribe({
      next: (v) => {
        const value = v['data'] as Record<string, any>;
        if (value) {
          value['records'] = value['records'].filter((option: any) =>
            option['status']
          );
          // console.log(value);
          let resultData = value['records'].find((o: any) => o.system_bank.code === this.currentDataBankUser['system_bank']['code']);
          // console.log(resultData)
          if (resultData === undefined) {
            // this.currentDataBankAdmin = this.listSelectBankCompany[0];
            let isDefault = value['records'].find((o: any) => o.group_default);
            if (isDefault) {
              this.currentDataBankAdmin = isDefault;
            } else {
              this.currentDataBankAdmin = value['records'][0];
            }
          } else {
            this.currentDataBankAdmin = resultData;
          }
          // console.log(this.currentDataBankAdmin);
          // console.log(this.currentDataBankAdmin);
          // if (this.currentDataBankAdmin === undefined) {
          //   this.currentDataBankAdmin = value['records'][0];
          // }
          if (this.currentDataBankAdmin['system_bank']['code'] === 'qris') {
            this.currentDataBankAdmin = value['records'][1];
          }

          if (this.currentDataBankAdmin['system_bank']['transaction_fee']) {
            this.currentFee = this.currentDataBankAdmin['system_bank']['transaction_fee']
          }
          this.listSelectBankCompany = value['records'];

          this.loader = false;
        }
      },
      error: (error) => {
        console.log(error)
      },
      complete: () => { }
    })
  }

  updateValueNominal(e: any) {
    let rawNominal = e.split('.').join("");
    this.inputAmount = parseInt(rawNominal);
  }

  withdrawBalance() {
    this.disabledButton = true;
    if (this.inputAmount > 0) {
      this.showNotif = false;
      this.requestService.createWithdraw(this.auth.sessionValue, this.currentDataBankUser['_id'], this.inputAmount as string, '').subscribe({
        next: (v) => {
          const value = v as Record<string, unknown>;
          // console.log(value);
          if (value['code'] === 200) {
            this.router.navigate(['/transaction']);
          }
        },
        error: (e) => {
          console.log(e)
          this.showNotif = true;
          this.disabledButton = false;
          this.showNotifText = e.error.messages[0].message;
        },
        complete: () => {
          this.disabledButton = false;
        }
      })
    } else {
      this.disabledButton = false;
      this.showNotif = true;
      this.showNotifText = 'Nominal Withdraw Kosong';
    }
  }

  subscriptionData() {
    if (this.currentScreenSize === 'desktop') {
      this.subscription.add(
        this.auth.getBalancevalue.subscribe({
          next: (v) => {
            let valTrunc = Math.floor(v);
            // var parts = valTrunc.toString().split('.');
            // parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            this.balance = valTrunc;
          },
        })
      );
      this.subscription.add(
        this.auth.getLoginStatus.subscribe({
          next: (v) => {
            // console.log(this.isLoggedin);
            this.isLoggedin = v;
          },
        })
      );
      this.subscription.add(this.route.queryParams.subscribe((params) => {
        params;
        // console.log(params);
        if(params['p'] === '1'){
          if (!this.showSelectPayment && !this.showCreateBank) {
            this.router.navigate(['/withdrawal'])
          }
          this.showSelectPayment = true;
          this.showCreateBank = false;
          this.showNotif = false;
        }
        else if(params['p'] === '2'){
          if (!this.showSelectPayment && !this.showCreateBank) {
            this.router.navigate(['/withdrawal'])
          }
          this.showSelectPayment = false;
          this.showCreateBank = true;
          this.showNotif = false;
        }
        else {
          // this.router.navigate(['/withdraw']);
          this.showCreateBank = false;
          this.showSelectPayment = false;
          this.showError = false;
        }
      }));
    }
  }

  submitPIN() {
    this.loadingSubmit = true;
    const userSecurity = this.auth.userSecurityData['pin'].toString();
    const arrFromPIN = Object.values(this.formPin);
    const PINvalue = arrFromPIN.join('');
    if (userSecurity === PINvalue) {
      this.loadingSubmit = false;
      this.showPin = false;
      this.showNotif = false;
      this.showNotifText = '';
      this.withdrawBalance();
    } else {
      this.showNotif = true;
      this.showNotifText = 'PIN yang anda input salah';
      this.loadingSubmit = false;
    }
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.optionsBank = this.dataService.bankRegisterJSONData['records'] as any[];
      this.searchedOptions = this.optionsBank;
      this.getBankCompany();
      this.subscriptionData();
      this.setLivechatURL();
      this.minWDAmount = this.dataService.transactionConfigJSONData['min_withdrawal_amount'];
      this.inputUserBankName = this.auth.userProfileData['first_name'] + (this.auth.userProfileData['last_name'] ? ' ' + this.auth.userProfileData['last_name'] : '');
      this.transactionConfig = this.dataService.transactionConfigJSONData;
    }
  }

  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.subscription.unsubscribe();
    }
  }
}
