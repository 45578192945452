<div class="header-container d-flex">
  <div class="latest-news">
    <div class="latest-news-left">
      <img src="../template/green/assets/images/ic-bullhorn.png" width="12" height="10" />
    </div>
    <div class="latest-news-right">
      <div class="latest-news-right-inner">
        <!-- @for (item of newstickerData; track item._id; let idx =
        $index) {
        <span class="newsticker-item">{{item.title}}</span>
        } -->
        <app-newsticker [data]="newstickerData"></app-newsticker>
      </div>
    </div>
  </div>
  <div class="header-right">
    <a class="container-event-click" [routerLink]="['/event']">
      <div class="event-button-container">
        <img src="../../../template/green/assets/images/event/{{websiteJSONData.template.misc.event_image}}/event.gif">
      </div>
    </a>
    @if(!isLoggedin){
    <a [routerLink]="['/login']">
      <button class="btn-login">LOGIN</button>
    </a>
    } @else{
    <button
      class="d-flex align-items-center justify-content-center btn-info gap-2 btn-profileinfo"
    >
      <a [routerLink]="['/profile']">
        <p class="text-nowrap text-uppercase">{{ username }}</p>
        @if(!loadBalance){
        <p class="text-nowrap fw-bold">Loading...</p>
        } @else {
        <p class="text-nowrap fw-bold">IDR {{balance | currency:' ' : 'symbol' : '1.0-0'}}</p>
        }
      </a>
      <img src="../template/green/assets/images/user-dummy.png" class="profile d-none display-600-none" />
    </button>
    } @if(!isLoggedin){
    <a [routerLink]="['/register']"> <button class="btn-register">REGISTER</button></a>
    } @else{
        <button class="btn-logout d-none display-600-flex" (click)="logout()">
          <fa-icon [icon]="faPowerOff" class="close-button"></fa-icon>
        </button>
        <button class="btn-logout d-none display-600-none" (click)="logout()">LOGOUT</button>
    }
  </div>
</div>
<!-- <div class="header-container-mobile d-md-none">
  <div class="header-middle">
    <div class="header-middle-center">
      <a href="/">
        <img src="../template/green/assets/images/login-img-mobile.png" />
      </a>
      <div class="d-flex">
        <form (keydown.enter)="searchItem()">
          <input
            type="text"
            class="form-control"
            placeholder="Search games.."
            [(ngModel)]="searchValue"
            name="searchValue"
            aria-label="Search games.."
          />
          <button type="button" class="btn" (click)="searchItem()">
            <fa-icon [icon]="faMagnifyingGlass" type="submit"></fa-icon>
          </button>
        </form>
        <button class="btn pt-0">
          <img src="../template/green/assets/images/ic-hamburger-menu.png" />
        </button>
      </div>
    </div>
    <div class="header-bottom-center w-100">
      <button>
        <img
          src="../../../template/green/assets/images/ic-list-filter.png"
          class="list-filter"
        />
        Filter
      </button>
    </div>
  </div>
</div> -->
