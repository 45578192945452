@if(websiteJSONData.template.misc.jackpot !== 'hide'){
  <div class="jackpot px-0 mx-0">
    <div class="px-0 mx-0 jackpot-inner">
      <span class="value" #a>
        <div class="blink-text" style="font-size: 3vw; margin-right: 60px;">IDR {{jackpotGlobalVal | formatNumberId}}</div>
      </span>
      <img ngSrc="../../../template/green/assets/images/jackpot/{{websiteJSONData.template.misc.jackpot}}/jackpot_desktop.gif"
        width="951" height="136" priority />
    </div>
  </div>
}
